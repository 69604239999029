import React from 'react';
import DocumentAnalysisApp from './DocumentAnalysisApp';

function App() {
  return (
    <div className="App">
      <DocumentAnalysisApp />
    </div>
  );
}

export default App;