import React, { useState } from 'react';
import axios from 'axios';
import { CheckCircle, AlertTriangle, XOctagon } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';
import { Button } from './components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Input } from './components/ui/input';
import { Label } from './components/ui/label';
import { Textarea } from './components/ui/textarea';
import PdfToText from 'react-pdftotext';

interface FlagItem {
  flag: string;
  page: number;
  paragraph: number;
}

interface Analysis {
  greenFlags: FlagItem[];
  yellowFlags: FlagItem[];
  redFlags: FlagItem[];
}

interface ApiResponse {
  status: string;
  message: string;
  initialResults: Analysis;
}

const FlagBucket: React.FC<{ title: string; icon: React.ElementType; color: string; items: FlagItem[] | undefined }> = ({ title, icon: Icon, color, items }) => (
  <Card className={`w-full md:w-1/3 p-4 border-${color}-500 animate-in fade-in duration-500`}>
    <CardHeader>
      <CardTitle className="flex items-center space-x-2">
        <Icon className={`text-${color}-500`} />
        <span>{title}</span>
      </CardTitle>
    </CardHeader>
    <CardContent>
      <ul className="space-y-2">
        {items && items.length > 0 ? (
          items.map((item, index) => (
            <li key={index} className="text-sm">
              {item.flag} <span className="text-xs text-gray-500">(Page {item.page}, Paragraph {item.paragraph})</span>
            </li>
          ))
        ) : (
          <li className="text-sm text-gray-500">No items to display</li>
        )}
      </ul>
    </CardContent>
  </Card>
);

export default function DocumentAnalysisApp() {
  const [file, setFile] = useState<File | null>(null);
  const [instructions, setInstructions] = useState('');
  const [analysis, setAnalysis] = useState<Analysis | null>(null);
  const [apiMessage, setApiMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      console.log('File selected:', e.target.files[0].name);
    }
  };

  const handleInstructionsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInstructions(e.target.value);
    console.log('Instructions updated, length:', e.target.value.length);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setAnalysis(null);
    setApiMessage(null);

    try {
      if (!file) {
        throw new Error('No file selected');
      }

      console.log('Extracting text from PDF...');
      try {
        const extractedText = await PdfToText(file);
        console.log('Extracted text length:', extractedText.length);

        console.log('Preparing form data for submission...');
        const formData = new FormData();
        formData.append('text', extractedText);
        formData.append('instructions', instructions);

        console.log('Sending POST request to API...');
        const response = await axios.post<ApiResponse | Analysis>(
          'https://rfp.paul-maxwell-campbell.workers.dev/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        console.log('Response received from API:', response.status);
        console.log('Full Response data:', JSON.stringify(response.data, null, 2));

        if ('initialResults' in response.data) {
          // It's an ApiResponse
          const apiResponse = response.data as ApiResponse;
          setAnalysis(apiResponse.initialResults);
          setApiMessage(apiResponse.message);
        } else {
          // It's a direct Analysis object
          setAnalysis(response.data as Analysis);
        }
      } catch (error) {
        console.error('Error extracting text from PDF:', error);
        setError('Failed to extract text from PDF. Please try again with a different file.');
      }
    } catch (error) {
      console.error('Error during API call:', error);
      if (axios.isAxiosError(error)) {
        if (error.response) {
          console.error('Response error data:', error.response.data);
          console.error('Response error status:', error.response.status);
          setError(`Server error: ${error.response.status} - ${JSON.stringify(error.response.data)}`);
        } else if (error.request) {
          console.error('Request was made but no response was received');
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          console.error('Error message:', error.message);
          setError(`Error: ${error.message}`);
        }
      } else {
        console.error('Non-Axios error:', error);
        setError('An unexpected error occurred. Please try again.');
      }
    }

    setLoading(false);
  };

  return (
    <div className="container mx-auto p-4 max-w-4xl">
      <h1 className="text-3xl font-bold mb-8 text-center">Document Analysis App</h1>
      
      <form onSubmit={handleSubmit} className="space-y-4 mb-8">
        <div>
          <Label htmlFor="file-upload">Upload Document</Label>
          <Input 
            id="file-upload" 
            type="file" 
            onChange={handleFileChange} 
            accept=".pdf"
          />
        </div>
        
        <div>
          <Label htmlFor="instructions">Analysis Instructions</Label>
          <Textarea
            id="instructions"
            placeholder="Enter specific instructions for analysis..."
            value={instructions}
            onChange={handleInstructionsChange}
          />
        </div>
        
        <Button type="submit" disabled={!file || !instructions || loading} className="w-full">
          {loading ? 'Analyzing...' : 'Analyze Document'}
        </Button>
      </form>

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}

      {apiMessage && (
        <Alert className="mb-4">
          <AlertTitle>API Message</AlertTitle>
          <AlertDescription>{apiMessage}</AlertDescription>
        </Alert>
      )}

      {analysis && (
        <div className="space-y-4">
          <h2 className="text-2xl font-semibold mb-4">Analysis Results</h2>
          <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
            <FlagBucket
              title="Green Flags"
              icon={CheckCircle}
              color="green"
              items={analysis.greenFlags}
            />
            <FlagBucket
              title="Yellow Flags"
              icon={AlertTriangle}
              color="yellow"
              items={analysis.yellowFlags}
            />
            <FlagBucket
              title="Red Flags"
              icon={XOctagon}
              color="red"
              items={analysis.redFlags}
            />
          </div>
        </div>
      )}
    </div>
  );
}